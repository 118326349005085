'use client';

import { useState } from 'react';
import Image from 'next/image';
import NextLink from 'next/link';
import { twMerge } from 'tailwind-merge';

import ChevronDown from '@/public/images/chevron-down.svg';
import { menuItems } from '@/src/defaults/menuDefaults';
import logo from '@/public/images/logo.png';
import menu from '@/public/images/menu.svg';

import Button from '../UI/Button';
import CloseButton from '../UI/CloseButton';
import Link from '../UI/Link';

const Nav = () => {
  const [isMenuShown, setIsMenuShown] = useState(false);
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState<number | null>(null);

  const toggleMenu = () => {
    setIsMenuShown(!isMenuShown);
  };

  const handleToggleSubmenu = (index: number) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
  };

  return (
    <header className="bg-blue border-b-black border-b-2 z-20 relative px-8">
      <nav
        role="navigation"
        className="max-w-theme mx-auto flex justify-between items-center py-6 relative"
      >
        <div>
          <NextLink aria-label="Home page" href="/" title="Home page">
            <Image
              height={56}
              width={230}
              priority
              className="no-repeat max-w-[230px]"
              alt="Passenger Logo"
              title="Passenger Logo"
              src={logo}
            />
          </NextLink>
        </div>
        <div
          aria-label="Open menu"
          aria-haspopup="menu"
          className="block cursor-pointer lg:hidden"
          onClick={toggleMenu}
          role="button"
        >
          <Image
            priority
            height={32}
            width={32}
            alt="Menu"
            title="Menu"
            src={menu}
          />
        </div>
        <div className="hidden lg:block">
          <ul className="flex items-center" role="menu">
            {menuItems.map((item, index) => (
              <li
                aria-haspopup={
                  'subMenu' in item && item.subMenu ? 'menu' : undefined
                }
                className="relative group"
                key={index}
              >
                {item?.href ? (
                  <Link
                    href={item.href}
                    style="px-4 text-base hover:underline"
                    title={item.title}
                  />
                ) : (
                  <span tabIndex={0} className="px-4 text-base">
                    {item.title}
                  </span>
                )}
                {'subMenu' in item && item.subMenu && (
                  <div className="hidden group-focus-visible:block group-focus-within:block group-hover:block absolute left-2 pt-4">
                    <ul className="bg-white w-[240px] border-2 border-black rounded-theme overflow-hidden">
                      {item.subMenu.map((subItem, subIndex) => (
                        <li
                          className="border-b-2 border-b-black last:border-b-0"
                          key={subIndex}
                        >
                          <Link
                            href={subItem.href}
                            style="w-full px-4 py-4 text-base hover:bg-blue block hover:underline focus-visible:bg-blue focus-visible:underline"
                            title={subItem.title}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
            <li className="ml-4" key="cta">
              <Button
                ariaLabel="Donate to Passenger"
                href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=RWC5JMVTEWTTL"
                target="_blank"
                title="Donate"
              />
            </li>
          </ul>
        </div>
      </nav>
      {isMenuShown && (
        <div className="fixed w-full h-full top-0 left-0 z-50" aria-modal>
          <div
            onClick={toggleMenu}
            className="w-full h-full absolute top-0 left-0 bg-black/[.5] z-30"
          />
          <div className="absolute right-0 top-0 h-full w-full max-w-[440px] bg-blue px-4 pt-16 z-50">
            <div className="absolute top-8 right-4">
              <CloseButton ariaLabel="Close menu" onClick={toggleMenu} />
            </div>
            <ul className="mb-6" role="menu">
              {menuItems.map((item, index) => (
                <li key={index} className="border-b border-white/10">
                  <div className="flex items-center">
                    {item?.href ? (
                      <Link
                        href={item.href}
                        style="flex-1 px-6 py-4 block text-base"
                        title={item.title}
                      />
                    ) : (
                      <span className="flex-1 px-6 py-4 block text-base">
                        {item.title}
                      </span>
                    )}
                    {'subMenu' in item && item.subMenu && (
                      <button
                        onClick={handleToggleSubmenu(index)}
                        className="p-4 text-white"
                        aria-expanded={openSubmenuIndex === index}
                        aria-label={`Toggle ${item.title} submenu`}
                      >
                        <Image
                          src={ChevronDown}
                          alt="Chevron down"
                          width={24}
                          height={24}
                        />
                      </button>
                    )}
                  </div>
                  {'subMenu' in item && item.subMenu && (
                    <div
                      className={twMerge(
                        'overflow-hidden transition-all duration-200',
                        openSubmenuIndex === index ? 'max-h-96' : 'max-h-0'
                      )}
                      role="region"
                      aria-labelledby={`submenu-${index}`}
                    >
                      <ul className="bg-white/5 py-2" role="menu">
                        {item.subMenu.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            <Link
                              href={subItem.href}
                              style="px-8 py-3 block text-sm"
                              title={subItem.title}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
            <Button
              ariaLabel="Donate to Passenger"
              href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=RWC5JMVTEWTTL"
              style="w-full justify-center"
              target="_blank"
              title="Donate"
            />
          </div>
        </div>
      )}
    </header>
  );
};

export default Nav;
